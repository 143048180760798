export default function CardItem(props) {
  const app = props.app;
  console.log("../assets/images/" + app.image);
  return (
    <div className="card" onClick={() => window.open(app.link, "_blank")}>
        <img src={"/images/" + app.image} alt="Placeholder" />
        <div className="card-content">
          <h3 className="title">{app.name}</h3>
          <p>{app.description}</p>
      </div>
    </div>
  );
}
