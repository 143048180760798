import React from "react";
import "./App.css";
import APPDATA from "./apps.json";
import CardItem from "./components/CardItem";

function App() {
  return (
    <div className="App">
      <div className="headerContainer">
        <h1>Tabletop RPG Web Tools</h1>
        <h2>Webtools designed to make playing tabletop games easy!</h2>
        <a href="https://github.com/Singhmansingh/" target="_blank" rel="noreferrer">Follow me @ Singhmansingh on Github</a>
      </div>
      <div className="body">
        <div className="card-container">
          {APPDATA.apps.map((a) => (
            <CardItem app={a} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
